<script setup lang="ts">
import { MinusIcon, NearIcon, PlusIcon } from '@glow/shared/components/icons';
import type { Coords } from '@glow/shared/lib/coords';
import {
  MapMarkerLayer,
  renderMarkerWithText,
  useMap,
} from '@glow/shared/lib/ol';

const props = withDefaults(
  defineProps<{
    markerLocation?: Coords;
  }>(),
  { markerLocation: undefined }
);

const mapElementRef = ref<HTMLElement>();
const mapEvents = reactive<{
  onZoomChange?: (delta: 1 | -1) => void;
  onSearchNear?: () => void;
}>({});
const detectGeolocation = useDetectGeolocation();

useMap(mapElementRef, {
  onReady: (map) => {
    const markerLayer = new MapMarkerLayer(map, {
      renderer: () => renderMarkerWithText('O'),
    });
    watch(
      () => props.markerLocation,
      (coords) => {
        if (!coords) return;
        markerLayer.setMarkers([{ coords }]);
        map.center = coords;
      },
      { immediate: true }
    );

    mapEvents.onZoomChange = (delta: 1 | -1) => {
      map.changeZoom(delta);
    };
    mapEvents.onSearchNear = async () => {
      const { coords } = await detectGeolocation();
      map.center = [coords.latitude, coords.longitude];
    };
  },
});
</script>

<template>
  <div class="simple-map">
    <div ref="mapElementRef" class="simple-map__map"></div>
    <div class="simple-map__zoom-buttons">
      <AppButton
        :icon-start="PlusIcon"
        border-radius="full"
        variant="secondary"
        color="light"
        class="simple-map__zoom-in-button shadow d-none d-md-block"
        @click="mapEvents.onZoomChange?.(1)"
      ></AppButton>
      <AppButton
        :icon-start="MinusIcon"
        border-radius="full"
        variant="secondary"
        color="light"
        class="simple-map__zoom-out-button shadow d-none d-md-block"
        @click="mapEvents.onZoomChange?.(-1)"
      ></AppButton>
    </div>
    <AppButton
      :icon-start="NearIcon"
      border-radius="full"
      variant="secondary"
      color="light"
      class="simple-map__near-button shadow"
      @click="mapEvents.onSearchNear?.()"
    ></AppButton>
  </div>
</template>

<style scoped lang="scss">
.simple-map {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &__map {
    width: 100%;
    height: 100%;
  }

  &__zoom-buttons {
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 7;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }

  &__zoom-in-button,
  &__zoom-out-button,
  &__near-button {
    padding: 0.7em;
    font-size: 15px;
  }

  &__near-button {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 7;
  }
}
</style>
